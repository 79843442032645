<template>
  <div
    class="orderlist bj order-container"
    v-loading="pageLoading"
    element-loading-text="正在生成供货单..."
  >
    <div>
      <!-- <div class="title">
                <el-button type="primary" icon="el-icon-refresh" class="bjsearch" @click="Refresh"></el-button>


            </div> -->
      <div class="seach order-wrapper">
        <el-button
          type="primary"
          icon="el-icon-refresh"
          class="bjsearch"
          @click="Refresh"
        ></el-button>
        <div class="inputs">
          <el-input
            v-model="from1.product_name"
            placeholder="商品名称/货号"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="from1.supplier_name"
            placeholder="请输入主供货商"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="from1.buyer_name"
            placeholder="请输入业务员"
            clearable
          ></el-input>
        </div>
        <div class="inputs multiple-select-long">
          <el-select
            @change="cityChange"
            filterable
            multiple
            v-model="from1.logistics_id"
            clearable
            placeholder="选择城市仓"
          >
            <li
              class="el-select-dropdown__item"
              :class="{ selected: serviceSelectedAll }"
              @click="onServiceSelectedAll"
            >
              <span>全选</span>
            </li>
            <el-option
              v-for="item in storelist"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-select
            v-model="from1.logistic_business_id"
            clearable
            placeholder="请选择集配中心"
          >
            <el-option
              v-for="item of options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs ">
          <el-select
            v-model="from1.delivery_id"
            multiple
            clearable
            placeholder="选择自提点"
          >
            <el-option
              v-for="item in selflist"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="inputs ">
          <el-select
            v-model="from1.is_morning"
            clearable
            placeholder="选择开市时间"
          >
            <!-- <el-option label="全部" value=""> </el-option> -->
            <el-option label="早市" value="1"> </el-option>
            <el-option label="午市" value="0"> </el-option>
          </el-select>
        </div>
        <el-button @click="search" type="primary" icon="el-icon-search"
          >查询</el-button
        >
        <el-button type="success" @click="listHandle">生成供货单</el-button>
        <!-- <el-button @click="exportHandle" type="warning" icon="el-icon-upload2">导出</el-button> -->
      </div>
    </div>
    <div class="auto-table-flex">
      <el-table
        :data="tableData"
        height="100%"
        v-loading="loading"
        :border="true"
        :header-cell-style="{
          color: '#333333',
          background: '#EFF6FF',
          'text-align': 'center',
        }"
        ref="multipleTable"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column prop="goodsName" align="center" label="主图">
          <template slot-scope="scope" v-if="scope.row.product">
            <el-image
              :src="scope.row.product.kv"
              :preview-src-list="[scope.row.product.kv]"
              class="kv pointer"
              alt=""
            />
          </template>
        </el-table-column>
        <el-table-column prop="title" align="left" width="250" label="商品信息">
          <template slot-scope="scope">
            <div v-if="scope.row.product">
              <div class="mb-15" style="font-weight: bold">
                <span v-if="scope.row.product.title">{{
                  scope.row.product.title
                }}</span>
                <span v-if="scope.row.product.spec"
                  >#{{ scope.row.product.spec }}</span
                ><span v-if="scope.row.product.sku_code"
                  >({{ scope.row.product.sku_code }})</span
                >
              </div>
              <div class="mb text-light">
                等级/规格：{{ $empty.empty(scope.row.product.grade) }}
                <span v-if="scope.row.product.unit_type == 1">/件</span>
                <span v-if="scope.row.product.unit_type == 2">/箱</span>
                <span v-if="scope.row.product.unit_type == 3">/袋子</span>
              </div>
              <div class="mb">
                毛重/毛单价：{{
                  $empty.empty(scope.row.product.gross_weight)
                }}
                【{{
                  tool.toDecimal2(
                    (((scope.row.product.price * 1000) /
                      scope.row.product.gross_weight) *
                      1000) /
                      100 /
                      1000000
                  )
                }}元/斤】
              </div>
              <div class="mb-15">
                净重/净单价：{{ $empty.empty(scope.row.product.weight) }}【{{
                  tool.toDecimal2(
                    (((scope.row.product.price * 1000) /
                      scope.row.product.weight) *
                      1000) /
                      100 /
                      1000000
                  )
                }}元/斤】
              </div>
            </div>
            <div v-else>—</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="logistics_business_name"
          align="center"
          label="集配中心"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.logistics_business_name">{{
              $empty.empty(scope.row.logistics_business_name)
            }}</span>
            <span v-else>—</span>
          </template>
        </el-table-column>
        <el-table-column prop="supplier_name" align="center" label="主供货商">
          <template slot-scope="scope">
            <span v-if="scope.row.supplier_name">{{
              $empty.empty(scope.row.supplier_name)
            }}</span>
            <span v-else>—</span>
          </template>
        </el-table-column>
        <el-table-column prop="logistics_name" align="center" label="城市仓">
          <template slot-scope="scope">
            <span v-if="scope.row.logistics_name">{{
              $empty.empty(scope.row.logistics_name)
            }}</span>
            <span v-else>—</span>
          </template>
        </el-table-column>
        <el-table-column prop="delivery_name" align="center" label="自提点">
          <template slot-scope="scope">
            <span v-if="scope.row.delivery_name">{{
              $empty.empty(scope.row.delivery_name)
            }}</span>
            <span v-else>—</span>
          </template>
        </el-table-column>
        <el-table-column prop="order_qty" align="center" label="订货数量">
          <template slot-scope="scope">
            <span>{{ $empty.empty(scope.row.order_qty) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="buyer_name" align="center" label="业务员">
          <template slot-scope="scope">
            <span>{{ $empty.empty(scope.row.buyer_name) }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { Loading } from "element-ui";
import { BASE } from "@/api";
import cloneDeep from "lodash/cloneDeep";
import { getOrderTaskStatus } from "@/api/generalControl/order-manage";
export default {
  name: "orderManage",
  components: {},
  data() {
    return {
      //列表新建页面初始参数
      loading: true,
      pageLoading: false,
      pay_at: "",
      from: {
        product_name: "",
        supplier_name: "",
        logistic_business_id: "",
        buyer_name: "",
        logistics_id: "",
        delivery_id: "",
        is_morning: "",
        page: 1,
        pageSize: 10,
      },
      from1: {
        product_name: "",
        supplier_name: "",
        logistic_business_id: "",
        buyer_name: "",
        logistics_id: "",
        delivery_id: "",
        is_morning: "",
        page: 1,
        pageSize: 10,
      },
      tableData: [],
      currentPage: 1,
      total: 0,
      //列表页面不同功能参数
      authentication: [
        {
          id: -1,
          name: "全部",
        },
        {
          id: 0,
          name: "未认证",
        },
        {
          id: 1,
          name: "审核中",
        },
        {
          id: 2,
          name: "审核成功",
        },
        {
          id: 3,
          name: "审核失败",
        },
      ],
      options: [
        {
          value: "logistics",
          label: "集配",
        },
        {
          value: "delivery",
          label: "自提",
        },
      ],
      options1: [],
      storelist: [],
      selflist: [],
      logistics_id: "",
      time: 1000,
      getTime: 0,
      count: 0,
      setTime: null,
      // load: null,
      list: [],
      list1: [],
    };
  },
  computed: {
    // 全选的勾选状态 true、false
    serviceSelectedAll() {
      return this.from1.logistics_id.length === this.storelist.length;
    },
    /**
     * 获得业务员名称
     */
    getSupplierBuyerName() {
      return (data) => {
        const res = data?.buyer?.fullname;
        return res || "-";
      };
    },
  },
  created() {
    this.hqlist();
    this.getBusinessList();
    this.citylist();
    //   this.seletlist();
  },
  methods: {
    getBusinessList() {
      this.$api.general.businessList().then((res) => {
        this.options = res.data;
      });
    },
    getListData(val) {
      this.$api.general
        .deliveryList({
          logistics_id: val, //必须填写
          page: 1,
          pageSize: 1000,
        })
        .then((res) => {
          console.log(res, "获取数据");
          this.selflist = res.data.data;
        });
    },
    exportHandle() {
      this.from = this.tool.DeepCopy({}, this.from1);
      let delivery_id = "",
        logistics_id = "";
      if (
        (Array.isArray(this.from.delivery_id) &&
          this.from.delivery_id.length == 0) ||
        !this.from.delivery_id
      ) {
        delete this.from.delivery_id;
      } else {
        this.from.delivery_id.forEach((item) => {
          delivery_id += "&delivery_id[]=" + item;
        });
        console.log(delivery_id);
      }
      if (
        (Array.isArray(this.from.logistics_id) &&
          this.from.logistics_id.length == 0) ||
        !this.from.logistics_id
      ) {
        delete this.from.logistics_id;
      } else {
        this.from.logistics_id.forEach((item) => {
          logistics_id += "&logistics_id[]=" + item;
        });
        console.log(logistics_id);
      }
      window.open(
        BASE.PRO1 +
          `/order/list/export?order_no=` +
          this.from.order_no +
          "&pay_end=" +
          this.from.pay_end +
          "&pay_start=" +
          this.from.pay_start +
          "&state=" +
          this.from.state +
          delivery_id +
          logistics_id +
          "&token=" +
          sessionStorage.getItem("token"),
        "_blank"
      );
      console.log(
        BASE.PRO1 +
          `/order/list/export?order_no=` +
          this.from.order_no +
          "&pay_end=" +
          this.from.pay_end +
          "&pay_start=" +
          this.from.pay_start +
          "&state=" +
          this.from.state +
          delivery_id +
          logistics_id +
          "&token=" +
          sessionStorage.getItem("token"),
        "_blank"
      );
      // this.$api.general.orderExport(this.from).then((res) => {
      // });
    },
    citylist() {
      this.$api.general
        .logisticsList({
          name: "",
          page: 1,
          pageSize: 1000,
        })
        .then((res) => {
          console.log(res, "获取数据");
          this.storelist = res.data.data;
        });
    },
    onServiceSelectedAll() {
      if (this.serviceSelectedAll) {
        // 取消全选
        this.from1.logistics_id = [];
      } else {
        this.from1.logistics_id = this.storelist.map((item) => item.id);
      }
    },
    cityChange(val) {
      console.log(val);
      this.getListData(val);
    },
    handleSelectionChange(val) {
      // this.multipleSelection = val;
      console.log(val);
    },
    toggleSelection() {
      this.$nextTick(() => {
        this.$refs.multipleTable.toggleAllSelection();
      });
    },
    /**
     * 轮询接口
     * @param {*} time:轮询间隔时间
     * @returns
     */
    async getResult(id) {
      this.count = this.count === 5 ? 5 : this.count + 1;
      try {
        let { data } = await getOrderTaskStatus({ task_id: id });
        if (data.status == 1) {
          this.count = 0;
          this.getTime = 0;
          this.time = 1000;
          // this.load.close();
          this.pageLoading = false;
          clearTimeout(this.setTime);
          this.tool.message("已生成", "success");
          this.hqlist();
        } else {
          if (this.$route.fullPath != "/orderManage") {
            this.count = 0;
            this.getTime = 0;
            this.time = 1000;
            // this.load.close();
            this.pageLoading = false;
            clearTimeout(this.setTime);
          } else {
            console.log("count++++", this.count);
            this.getTime = this.time * this.count;
            console.log("time++++", this.time);
            console.log("getTime++++", this.getTime);
            this.setTime = setTimeout(() => {
              this.getResult(id);
            }, this.getTime);
          }
        }
      } catch (err) {
        this.count = 0;
        this.getTime = 0;
        this.time = 1000;
        // this.load.close();
        this.pageLoading = false;
        console.log("ajax getOrderTaskStatus err", err);
      } finally {
      }
    },
    listHandle() {
      this.$confirm("确定要生成供货单吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.pageLoading = true;
          // this.load = Loading.service({ fullscreen: false });
          this.tool.debounce(() => {
            this.from = this.tool.DeepCopy({}, this.from1);
            if (
              (Array.isArray(this.from.delivery_id) &&
                this.from.delivery_id.length == 0) ||
              !this.from.delivery_id
            ) {
              delete this.from.delivery_id;
            }
            if (
              (Array.isArray(this.from.logistics_id) &&
                this.from.logistics_id.length == 0) ||
              !this.from.logistics_id
            ) {
              delete this.from.logistics_id;
            }
            let params = cloneDeep(this.from);
            delete params.page;
            delete params.pageSize;
            this.$api.general
              .addOrder1(params)
              .then((res) => {
                this.time = 1000;
                this.getTime = 0;
                this.count = 0;
                this.getResult(res.data.task_id);
                // this.tool.message('已生成', 'success')
                // load.close()
                // this.Refresh()
              })
              .catch((error) => {
                this.pageLoading = false;
                // this.load.close();
              });
          });
        })
        .catch(() => {});
    },
    search() {
      this.from.page = 1;
      this.from1.page = 1;
      this.currentPage = 1;
      this.from = this.tool.DeepCopy({}, this.from1);
      if (
        (Array.isArray(this.from.delivery_id) &&
          this.from.delivery_id.length == 0) ||
        !this.from.delivery_id
      ) {
        delete this.from.delivery_id;
      }
      if (
        (Array.isArray(this.from.logistics_id) &&
          this.from.logistics_id.length == 0) ||
        !this.from.logistics_id
      ) {
        delete this.from.logistics_id;
      }
      this.hqlist();
    },
    changestore(e) {
      // console.log(e);
      this.ruleForm.name = JSON.parse(e).name;
      this.ruleForm.store_id = JSON.parse(e).id;
    },
    addclick() {
      console.log("新增");

      this.ruleForm = {
        business_type_code: "logistics", //集配：logistics 自提点：delivery
        store_id: "",
        name: "",
        itemdata: "",
        username: "",
        password: "",
      };

      this.centerDialogVisible = true;
      this.title = "新增中心账号";
    },
    tianku() {
      console.log(this.ruleForm);
      this.centerDialogVisible = false;
      this.$api.login.addbusiness(this.ruleForm).then((res) => {
        this.$message({
          type: "success",
          message: "添加成功",
        });
        this.Refresh();
      });
    },
    Refresh() {
      this.from = {
        product_name: "",
        supplier_name: "",
        logistic_business_id: "",
        buyer_name: "",
        logistics_id: "",
        delivery_id: "",
        is_morning: "",
        page: 1,
        pageSize: 10,
      };
      this.from1 = {
        product_name: "",
        supplier_name: "",
        logistic_business_id: "",
        buyer_name: "",
        logistics_id: "",
        delivery_id: "",
        is_morning: "",
        page: 1,
        pageSize: 10,
      };
      this.currentPage = 1;
      this.hqlist();
    },
    handleSizeChange(val) {
      //   console.log(`每页 ${val} 条`);
      this.from.pageSize = val;
      this.from1.pageSize = val;
      this.hqlist();
    },
    handleCurrentChange(val) {
      this.from.page = val;
      this.from1.page = val;
      this.currentPage = val;
      this.hqlist();
    },
    payChange(val) {
      console.log(val);
      if (val) {
        this.from1.pay_start = val[0];
        this.from1.pay_end = val[1];
      } else {
        this.from1.pay_start = "";
        this.from1.pay_end = "";
      }
    },
    handleEdit(index, row) {
      this.$confirm(
        row.status === 1
          ? "确定要禁用该中心账号吗？"
          : "确定要启用该中心账户吗？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        }
      )
        .then(() => {
          this.$api.login
            .businessstatus({
              id: row.id,
              status: row.status === 1 ? 0 : 1,
            })
            .then((res) => {
              console.log(res, "禁用或者启用");
              this.$message({
                type: "success",
                message: row.status === 1 ? "禁用成功" : "启用成功",
              });
              this.hqlist();
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: row.status === 1 ? "取消禁用" : "取消启用",
          });
        });
    },

    selectda(index, row) {
      this.title = "修改中心账号";
      this.ruleForm.business_type_code = row.business_type_code;
      //修改参数显示不全，问题
      // if (row.business_type_code == "logistics") {
      //   this.list.forEach((el) => {
      //     if (el.id == row.store_id) {
      //       this.ruleForm.itemdata = JSON.stringify(el);
      //     }
      //   });
      // } else {
      //   this.list1.forEach((el) => {
      //     if (el.id == row.store_id) {
      //       this.ruleForm.itemdata = JSON.stringify(el);
      //     }
      //   });
      // }
      console.log(this.ruleForm, this.list, this.list);
      this.centerDialogVisible = true;
      // this.shownode = false;
      // this.$api.shipper.shipperinfo({ id: row.id }).then((res) => {
      //   console.log(res, "获取数据");
      //   this.info = res.data;
      //
      // });
    },
    hqlist() {
      this.loading = true;
      this.$api.general.calmList(this.from).then((res) => {
        console.log(res, "获取数据");
        this.tableData = res.data.data;
        this.total = res.data.total;
        this.loading = false;
      });
    },
    seletlist() {
      this.$api.login.unusedall().then((res) => {
        console.log(res, "获取所有可配置的自提点");
        this.list1 = res.data;
      });
      this.$api.login.logisticsunusedall().then((res) => {
        console.log(res, "获取所有可配置的城市仓");

        this.list = res.data;
      });
    },
  },
};
</script>
<style lang="scss">
.order-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  //   background: #ffffff;
  padding: 10px;
  .kv {
    width: 100px;
    height: 100px;
  }
  .checked-box {
    padding: 13px 15px;
  }
  .bjsearch {
    margin-right: 2px;
    background: #333333;
    border-color: #333333;
  }
  .order-wrapper {
    display: flex;
    align-items: flex-start;
    background: #ffffff;
    padding: 10px 15px;
    flex-wrap: wrap;
    .inputs {
      margin-right: 10px;
      margin-bottom: 10px;
    }

    .multiple-select {
      width: 420px;
    }

    .multiple-select-long {
      width: 420px;
    }
  }

  .title {
    background: #ffffff;
    padding-top: 10px;
    padding-left: 15px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;

    .seach {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      flex-wrap: wrap;

      .inputs {
        width: 207px;
        margin-right: 8px;
      }

      .multiple-select {
        width: 250px;
      }
    }
  }

  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }

  .fy {
    // background-color: #000;
    box-sizing: border-box;
    padding: 10px 20px;
  }

  .contents {
    padding-left: 55px;
    padding-right: 55px;
    padding-top: 23px;

    box-sizing: border-box;

    .titlname {
      font-size: 17px;
      color: #21c2ac;
      margin-bottom: 25px;
    }

    .imgs {
      display: flex;

      .imglist {
        width: 165px;
        height: 123px;
        border: 1px dashed #a4adc5;
        padding: 14px 8px;
        text-align: center;
        border-radius: 7px;
        box-sizing: border-box;
        margin-right: 16px;

        .div {
          margin-top: 20px;
          padding-bottom: 20px;
        }

        // img{
        //     width: 100%;
        //     height: 100%;
        // }
      }
    }

    .disply {
      display: flex;
      margin-bottom: 22px;
    }

    .mr {
      margin-right: 31px;
    }

    .iteminput {
      display: flex;
      align-items: center;

      .wen {
        width: 77px;
        margin-right: 24px;
        color: #000;
        font-size: 14px;
        text-align: right;
      }

      .el-input {
        width: 302px;
      }
    }
  }

  .colors {
    color: #fa884c;
  }

  .times {
    margin-right: 8px;
  }

  // .el-picker-panel {
  //   left: 1245px !important;
  // }
}
</style>
